<template>
    <div>
        <el-dialog title="表结构" :visible.sync="dialogTableVisible" :close-on-click-modal="false" append-to-body class="dialogClass"  width="1500px" :before-close="handleClose">
            <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    @selection-change="handleSelectionChange">
                <el-table-column label="序号" type="index" min-width="5%" class-name="allowDrag" />
                <el-table-column
                        label="字段列名"
                        prop="columnName"
                        min-width="10%"
                        :show-overflow-tooltip="true"
                />
                <el-table-column label="字段描述" min-width="10%">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.columnComment"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        label="物理类型"
                        prop="columnType"
                        min-width="10%"
                        :show-overflow-tooltip="true"
                />
            </el-table>
            <el-form label-width="100px">
                <el-form-item style="text-align: center;margin-left:-100px;margin-top:10px;">
                    <el-button @click="handleClose()">返回</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "updateTableColumn",
        data(){
            return{
                dialogTableVisible:false,
                tableData:[],
                multipleSelection:[],
                tableName:'',
                searchForm:{},
            }
        },
        methods:{
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleClose() {
                this.dialogTableVisible=false
                this.tableData=[]
                this.multipleSelection=[]
                this.tableName=''
                this.searchForm={}
                this.$emit('refreshDataList')
            },
            getCollectionResults(){
                this.searchForm.tableName=this.tableName
                this.$axios.post("/admin/acquisitiontask/getTableColumn", this.searchForm).then(res => {
                    this.tableData = res.data
                })
            },
            init(tableName){
                console.log("ssdsd",tableName)
                this.dialogTableVisible=true
                this.tableName=tableName
                this.getCollectionResults()
            },
        }
    }
</script>

<style scoped>

</style>
